.App {
  background-color: #000;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-container {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.spinning-image {
  height: 300px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stars-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  animation: twinkle 2s infinite;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Make sure the Devons appear above the stars */
.rocking-container {
  z-index: 1;
}

.spawned-devon {
  pointer-events: none;
  width: 100px;
  height: 100px;
  animation: spawnAnimation 2s ease-out forwards;
}

.spawned-devon img {
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

@keyframes spawnAnimation {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.donate-button {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 12px 24px;
  font-size: 2.0rem;
  background-color: #FF69B4;
  color: #282c34;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Brush Script MT', cursive;
}

.donate-button:hover {
  background-color: #FF1493;
  transform: translateX(-50%) scale(1.05);
  box-shadow: 0 0 15px rgba(255, 105, 180, 0.5);
}
